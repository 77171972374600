$default-map-height: 20%;

.app-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.g-container {
  position: relative;
  flex: 10;
}

#pano {
  height: 100% - $default-map-height;
}

.map-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  position: absolute;
  z-index: 5;
  pointer-events: none;
}

.map-container {
  position: relative;
  height: $default-map-height;
  pointer-events: auto;
  transition: 300ms;
}

#map {
  width: 100%;
  height: 100%;
}

.info-bar {
  display: flex;
  width: 100%;
  height: 70px;

  .s1 {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}

.info-bar-stats {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  > div {
    height: 100%;
    width: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: #999;
    }

    &.user {
      p {
        color: #333;
        background: #eee;
        padding: 2px 10px;
        line-height: 1;
        min-width: 12px;
        border-radius: 4px;
        transform: translate(12px, -8px);
        font-size: 0.8rem;
      }

      img {
        height: 40px;
      }
    }
  }
}

.centered-items {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.expand-btn-wrapper {
  position: absolute;
  z-index: 6;
  width: 100%;
  margin-top: -16px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.pill-btn {
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  background: white;
  padding: 4px 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background: #ecd873;   
  }
}

.location-card-wrapper {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.card {
  margin: 40px;
  width: 300px;
  height: 80%;
  max-height: 480px;
  min-height: 320px;
  pointer-events: auto;
  background: #fff;
  box-shadow: 0 0 8px #999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .card-content { 
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      margin: 10px 0;
    }
  }

  .card-image {
    margin: 0; 
    width: 120px;
    height: 120px;
    min-height: 120px;
    border-radius: 50%;
    border: 4px solid #eee;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
      height: 120px;
    }
  }
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
